@font-face {
  font-family: Wasa;
  src: url(fonts/Wasa-Trial-Regular.otf);
}

*,
*::after,
*::before {
  box-sizing: border-box;
}
*:focus {
  outline: none;
}

:root {
  font-size: 14px;
}

html {
  scroll-behavior: smooth;
  font-family: Wasa, Helvetica, sans-serif, campaign, -apple-system,
    BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif;
}
body {
  margin: 0;
  --color-text: black;
  --color-bg: #cdced3;
  --color-title: var(--color-text);
  --color-link: #000;
  --color-link-hover: #242222;
  --color-menu-link: #000;
  --color-menu-link-hover: #000;
  color: var(--color-text);
  background-color: var(--color-bg);
  font-family: Wasa, FHOscar, Arial, Helvetica, sans-serif, campaign,
    -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --button-color: #fff;
  --button-bg: #000;
}

.preloader {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  z-index: 99999999;
  background-image: url("/assets/loader.gif"); /* your icon gif file path */
  background-repeat: no-repeat;
  background-color: #fff;
  background-position: center;
}
#stop-scrolling {
  height: 100% !important;
  overflow: hidden !important;
}
.preloader h1 {
  text-align: center;
}

main {
  padding: 0;
  margin-top: -20px;
}
audio {
  width: 100%;
  height: 30px;
}

/* Style the header with a grey background and some padding */
header {
  grid-area: header;

  /*padding: 10px 10px;*/
  position: relative;
  z-index: 3;
}

/* Style the header links */
header a {
  color: black;
  padding: 12px;
  text-decoration: none;
  font-size: 18px;
  line-height: 55px;
  border-radius: 4px;
}
/* Style the logo link (notice that we set the same value of line-height and font-size to prevent the header to increase when the font gets bigger */
header a.logo {
  float: left;
  font-size: 55px;
  font-weight: bold;
  position: absolute;
  top: 0.5em;
  left: 0.1em;
}
header a.logoabout {
  float: right;
  font-size: 55px;
  font-weight: bold;
  position: absolute;
  top: 0.5em;
  right: 0.2em;
}

a {
  text-decoration: none;
  color: var(--color-link);
  outline: none;
  color: #f2f2f2;
  font-style: normal;
  font-weight: normal;
  font-size: 3em;
}

a:hover,
a:focus {
  color: var(--color-link-hover);
  outline: none;
}

.hidden {
  display: none;
}

ul {
  list-style-type: none;
  margin: 3em;
  padding: 0;
}

ol {
  margin: 1em;
}

th,
td {
  text-align: left;
  vertical-align: top;
  height: -50px;
}

th,
td h2 h4 {
  margin: 0;
  padding: 0;
}

h1 {
  margin-top: 0;
  font-size: 55px;
  font-weight: bold;
  position: relative;
  top: 0.5em;
  left: 0.1em;
}
h4 {
  font-size: 12px;
  font-style: italic;
  font-weight: 200;
}

/*SVGfilter*/
.menu {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: left;
  margin-bottom: 2rem;
}

.menu__link {
  flex: none;
  padding-bottom: 0.5rem;
  font-size: 1.85rem;
  position: relative;
  line-height: 1;
  color: var(--color-menu-link);
  margin-bottom: 2.5rem;
}

.menu__link:focus,
.menu__link:hover {
  color: var(--color-menu-link-hover);
}

.menu__link-inner {
  display: block;
  position: relative;
  outline: 100px solid transparent;
}

.menu__link-deco {
  outline: 120px solid transparent;
  opacity: 0;
  position: absolute;
  pointer-events: none;
}

.menu--line .menu__link-deco,
.menu--linethrough .menu__link-deco,
.menu--twolines .menu__link-deco,
.menu--diagonal .menu__link-deco {
  bottom: 0;
  left: 0;
  height: 1px;
  width: 100%;
  background: currentColor;
}

.menu--linethrough .menu__link-deco {
  bottom: 50%;
  height: 3px;
}

.menu--twolines .menu__link-deco {
  height: 100%;
  background: none;
  border: 1px solid currentColor;
  border-right: 0;
  border-left: 0;
}

.menu--twolines .menu__link {
  padding: 0.95rem 0;
}

.menu--circle .menu__link-deco,
.menu--box .menu__link-deco {
  left: 50%;
  top: 50%;
  width: 5rem;
  height: 5rem;
  margin: -2.5rem 0 0 -2.5rem;
}

.menu--circle .menu__link-deco {
  border-radius: 50%;
  border: 1px solid black;
}

.menu--box .menu__link-deco {
  border: 1px solid black;
}

.menu--diagonal .menu__link-deco {
  bottom: 50%;
  width: 9rem;
  left: 50%;
  margin-left: -4.5rem;
  transform: rotate(-45deg);
}

.menu__link:hover .menu__link-deco {
  opacity: 1;
}
/*SVGfilter*/

.playerbackground {
  z-index: 3;
  font-size: 16px;
  color: rgb(87, 79, 79);
  position: sticky;
  bottom: 20px;
  top: 94%;
  left: 3%;
  transform: translate(-50%, -50%);
  width: 50px;
  padding: 15px;
  margin: -15px;
  border: none;
  border-radius: 2px;
  outline: none;
  background: rgba(199, 192, 207, 0.8);
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
  opacity: 0.8;
  cursor: pointer;
  transition: all 0.4s ease-out;
}

.fade:hover {
  opacity: 0.8;
}

.fade {
  opacity: 0.2;
  top: 3%;
  left: 95%;
  transform: translate(0, 0);
}

/*Model-viewer*/
model-viewer {
  width: 100%;
  height: 100vh;
  z-index: 1;
}
model-viewer#reveal {
  --poster-color: transparent;
}
.Hotspot {
  background: #fff;
  border-radius: 32px;
  border: 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  cursor: pointer;
  height: 2px;
  padding: 2px;
  position: relative;
  transition: opacity 0.3s;
  width: 20px;
}

.Hotspot:not([data-visible]) {
  background: transparent;
  border: 4px solid #fff;
  box-shadow: none;
  height: 30px;
  pointer-events: none;
  width: 32px;
}

.Hotspot > * {
  opacity: 1;
  transform: translateY(-50%);
}

.HotspotAnnotation {
  border-radius: 2px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  color: rgba(255, 255, 255, 0.8);
  background: rgba(199, 192, 207, 0.8);
  font-size: 30px;
  font-weight: 300;
}

.Hotspot:not([data-visible]) > * {
  opacity: 0;
  pointer-events: none;
  transform: translateY(calc(-50% + 40px));
  transition: transform 0.3s, opacity 0.3s;
}
/*Model-viewer*/

/*tooltip*/
.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 420px;
  background-color: #cdced3;
  font-family: Wasa, FHOscar, Arial, Helvetica, sans-serif, campaign,
    -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif;
  color: black;
  font-size: 18px;
  text-align: center;
  padding: 10px 10px;

  /* Position the tooltip */
  position: absolute;
  top: 50%;
  left: 2%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
/*tooltip*/

/*Episodes*/
.cards {
  padding: 15px;
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  margin-left: 3.5em;
}
.card {
  flex: 0 1 33%;
  padding: 8px;
}

.card h1 {
  font-size: 2em;
}
.titleep {
  color: #666;
}
.title {
  color: #666;
  transition: 0.2s;
}
.title:hover {
  color: #000;
  transform: scale(1.2);
  translate: 50px;
}
.soon {
  opacity: 0;
}
/*Episodes*/

/*Library*/
#customers {
  border-collapse: collapse;
  width: 85%;
  margin: auto;
  border: 0;
}

#customers td,
#customers th {
  border: 0px;
  padding: 15px;
  transition: 0.2s;
}

#customers th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  color: white;
  font-weight: 700;
  font-size: 18px;
}

#customers td:hover {
  color: #000;
  transform: scale(1.2);
  translate: 50px;
}

#customers td a {
  font-size: 20px;
  line-height: 10px;
  outline: none;
}

/*Library*/

/*smallportview*/
@media only screen and (max-width: 600px) {
  main {
    display: none;
  }
  .preloader {
    display: none;
  }
}

@media only screen and (min-width: 600px) {
  .phone {
    display: none;
  }
}
.phone {
  background-color: #cdced3;
  width: 100%;
  margin: 10px;
}

.phone audio {
  /*margin: auto;*/
  width: 70%;
}
.phone hr {
  text-align: left;
  padding-left: 6px;
  margin: 0;
}
.phone h1 {
  font-size: 2rem;
  font-weight: bold;
}
.phone a {
  font-size: 2rem;
  font-weight: bold;
  color: black;
}
.phone h1 {
  font-size: 1.5rem;
}
.phone table {
  /*margin: auto;*/
  width: 80%;
}
.phonecast {
  padding-bottom: 20px;
}
.audio {
  display: none;
}
/*smallportview*/

.overlay {
  overflow: hidden;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  background-color: #cdced3;
}

.card:hover .overlay {
  opacity: 1;
}

.play-button {
  border: none;
  padding: 15px 32px;
  margin: 1%;
  text-align: right;
  border-radius: 18px;
  text-decoration: none;
}

.play-button:hover {
  border-radius: 50px;
  background: linear-gradient(145deg, #dbdce2, #b9b9be);
  box-shadow: 10px 10px 20px #ecedf3, -10px -10px 20px #aeafb3;
}

.active,
.btn:hover {
  color: rgb(87, 79, 79);
  background: rgba(199, 192, 207, 0.8);
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
  opacity: 0.8;
  cursor: pointer;
  transition: all 0.4s ease-out;
}
